import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import React, { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { Loading } from './components/Loading.tsx'
import { getAppConfig, loadRemoteConfig } from './config.ts'
import { AppContainer } from './styled.ts'

const bootstrapApp = async (): Promise<void> => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  await loadRemoteConfig()
}

// We load App with lazy as it blocks the rest of the app to init
// before remote config is loaded - prevents from injecting values
// loaded during build time
const App = lazy(() => import('./App.tsx'))

void bootstrapApp().then(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: getAppConfig().launchDarklyClientId,
    options: {
      streaming: true
    }
  })

  await i18n
    .use(LanguageDetector)
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      debug: process.env.NODE_ENV === 'development',
      fallbackLng: 'en-US',
      interpolation: {
        escapeValue: false
      }
    })

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <LDProvider>
        <Suspense
          fallback={
            <AppContainer>
              <Loading />
            </AppContainer>
          }
        >
          <App />
        </Suspense>
      </LDProvider>
    </React.StrictMode>
  )
})
