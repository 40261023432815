import { Box, CircularProgress, Typography, styled } from '@mui/material'

const LoadingContainer = styled(Box)({
  textAlign: 'center'
})

interface LoadingProps {
  text?: string
}

export const Loading = ({ text }: LoadingProps): JSX.Element => {
  return (
    <LoadingContainer>
      <CircularProgress />
      {text && <Typography color="info.main">{text}</Typography>}
    </LoadingContainer>
  )
}
