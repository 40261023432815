import ErrorIcon from '@mui/icons-material/Error'
import { Box, styled } from '@mui/material'

export const AppContainer = styled(Box)(() => ({
  display: 'flex',
  position: 'absolute',
  inset: 0,
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
}))

export const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '3.75rem',
  marginBottom: theme.spacing(2)
}))
